<template>
  <section>
      <div class="content-header">
        <h2>Works cited</h2>
      </div>
      <div class="content-wrapper">
        <ul>
          <li class="pb-2">Brecher, D. (2021). Resilience lecture.</li>
          <li class="pb-2">Ungar, M. (2021). <em>Multisystemic Resilience</em>. Oxford University Press.
</li>
          <li class="pb-2">St. John, B., & Haines, A. P. (2017). Micro-Resilience: Minor Shifts for Major Boosts in Focus, Drive, and Energy (1st Edition). Center Street.</li>
          <li >Brecher, D. (2019). Cultivate Your Happiness Weekly Workbook (Facilitator's Manual).</li>
        </ul>
      </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '06',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>

</style>
